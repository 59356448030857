import { useTranslation } from "next-i18next";
import MakeIcon from "@components/layout/SearchHeader/FilterBoxIcon";
import { Contains } from "@constants/contains";
import { IC_TYPE } from "@constants/common";
export default function FilterBoxRecommend({
  searchKeywords,
  handler
}) {
  const {
    t
  } = useTranslation("common");
  return <div className="flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start">
      <div className="SB_14_100_Medium tablet:SB_14_100_Medium relative flex
                   flex-shrink-0 flex-grow-0 items-center justify-start gap-3
                   self-stretch px-5 py-2.5 text-Gray-700 tablet:px-4">
        {t("searchbar.txt.trip.recommend")}
      </div>
      {searchKeywords?.map((item, index) => <div key={index} id={IC_TYPE.RECOMMEND.indexOf(item.type) > -1 ? "searching_recommand_keyword" : "searching_recommand_region"} className="relative flex flex-shrink-0 flex-grow-0
                     cursor-pointer items-center justify-start gap-3 self-stretch bg-white
                     px-6 py-2 hover:bg-Bg-OffWhite tablet:h-[54px] tablet:gap-2 tablet:px-4" onClick={() => {
      handler(`${item.region_id}`, item["country"] ? `${item["keyword"]}, ${item["country"]}` : `${item["keyword"]}`, "", item["keyword"]);
    }}>
          {MakeIcon(Contains.REGION, item.type)}
          <div className="flex grow flex-col overflow-hidden">
            <p className={`flex-grow text-Gray-900 ${IC_TYPE.RECOMMEND.indexOf(item.type) > -1 ? "SB_14_100_Bold tablet:P_15_160_Bold" : "SB_14_100_Regular tablet:B_16_100_Medium"}`}>
              {item["keyword"]}
            </p>
            {IC_TYPE.RECOMMEND.indexOf(item.type) === -1 && <p className="C_12_100_Regular mt-1 text-Gray-500 tablet:text-Gray-600">
                {item["country"]}
              </p>}
          </div>
        </div>)}
    </div>;
}