import React from "react";

/**
 * 기본 탭 컴포넌트
 * @param tabArr // 탭 배열 (string[])
 * @param selectTab
 * @param calssName
 * @param tabBtnClass
 * @param customTabBtn
 * @param callbacks
 * @returns {JSX.Element}
 * @constructor
 */
const Tab = ({
  tabArr,
  selectTab,
  calssName,
  tabBtnClass,
  customTabBtn,
  callbacks
}) => {
  const onClick = tab => {
    callbacks(tab);
  };
  return <div className={`${calssName ? calssName : "flex h-[48px] items-center justify-center text-center "}`}>
      <div className="relative flex h-[48px] w-full flex-row">
        {tabArr.map((v, i) => {
        return <div key={i} className={v === "" ? "w-0" : `${tabBtnClass ? tabBtnClass : "relative flex h-full w-full cursor-pointer items-center justify-center"}`} onClick={() => onClick(i)}>
              {customTabBtn ? customTabBtn(v, i) : <>
                  <span className="B_16_100_Bold text-Gray-900">{v}</span>
                  {selectTab === i ? <div className="absolute bottom-0 h-[3px] w-full rounded-t bg-Gray-900"></div> : <div className="absolute bottom-0 h-[1px] w-full bg-[#e6e6e6]"></div>}
                </>}
            </div>;
      })}
      </div>
    </div>;
};
export default Tab;