import { checkoutInfo } from "@modules/lib/fpixel/data/checkout";
import { hotelsInfo } from "@modules/lib/fpixel/data/hotel";
import { signup } from "@modules/lib/fpixel/data/signup";
import { search } from "@modules/lib/fpixel/data/search";
import { FACEBOOK_PIXEL_TRACKING_TYPE } from "@constants/scripts";

export const fPixelDataFormat = (type, data) => {
  if (type === FACEBOOK_PIXEL_TRACKING_TYPE.CHECKOUT) {
    return checkoutInfo(data);
  } else if (type === FACEBOOK_PIXEL_TRACKING_TYPE.VIEW_CONTENT) {
    return hotelsInfo(data);
  } else if (type === FACEBOOK_PIXEL_TRACKING_TYPE.SIGNUP) {
    return signup(data);
  } else if (type === FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH) {
    return search(data);
  }
};
