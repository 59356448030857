import { getLocalStorage, setLocalStorage } from "@modules/utils/storage";
import dayjs from "dayjs";
import {
  getRoomQuery,
  getSearchDateCookie,
  getSearchRoomsCookie,
} from "@modules/utils/SearchDataHandler";
import { v4 as uuidv4 } from "uuid";

const MAX_SEARCH_HISTORY = 10;
const MAX_SEARCH_HISTORY_AGE = 7;

export const setSearchHistory = (item, date) => {
  const roomsInfo = getSearchRoomsCookie()
    ? getSearchRoomsCookie()
    : [
        {
          uuid: uuidv4(),
          people: { adultCnt: 2, childs: [] },
        },
      ];
  const searchData = {
    startDate: getSearchDateCookie().startDate,
    endDate: getSearchDateCookie().endDate,
    roomsInfo: roomsInfo,
  };
  const searchQuery = {
    "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY-MM-DD"),
    "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY-MM-DD"),
    ...getRoomQuery(roomsInfo),
  };
  let history = getLocalStorage("searchHistory", []);

  const newEntry = {
    pathname: "/search",
    item: item,
    date: dayjs().format(), // 현재 날짜 추가
    searchData: date ? date.searchData : searchData,
    searchQuery: date ? date.searchQuery : searchQuery,
  };

  // 동일한 searchText를 가진 항목이 기록에 존재하는지 확인
  history = history.filter(entry => {
    const newEntryIdentifier = newEntry.item.name || newEntry.item.searchText;
    const entryIdentifier = entry.item.name || entry.item.searchText;

    // 두 식별자 중 하나라도 undefined인 경우를 처리
    if (!newEntryIdentifier || !entryIdentifier) {
      return true; // 식별자가 없는 항목은 유지
    }

    return entryIdentifier !== newEntryIdentifier;
  });

  // 새로운 기록을 맨 앞에 추가
  history.unshift(newEntry);

  // 최대 검색 기록을 초과하는 경우 오래된 기록을 삭제
  if (history.length > MAX_SEARCH_HISTORY) {
    history.pop();
  }

  // 새로운 검색 기록을 로컬 스토리지에 저장
  setLocalStorage("searchHistory", history);
};

export const getSearchHistory = () => {
  let history = getLocalStorage("searchHistory", []);
  const { startDate: cookieStartDate, endDate: cookieEndDate } = getSearchDateCookie();

  // 7일이 지난 기록은 제거
  const today = dayjs();
  history = history.filter(entry => {
    const isWithinSevenDays = today.diff(dayjs(entry.date), "day") < MAX_SEARCH_HISTORY_AGE;

    if (isWithinSevenDays) {
      const entryStartDate = dayjs(entry.searchData.startDate);

      if (entryStartDate.isBefore(today, "day")) {
        // 과거 날짜인 경우 쿠키의 날짜로 업데이트
        entry.searchData.startDate = cookieStartDate;
        entry.searchData.endDate = cookieEndDate;

        // searchQuery도 업데이트
        entry.searchQuery["check-in"] = dayjs(cookieStartDate).format("YYYY-MM-DD");
        entry.searchQuery["check-out"] = dayjs(cookieEndDate).format("YYYY-MM-DD");
      }
      return true;
    }
    return false;
  });

  // 변경된 검색 기록을 로컬 스토리지에 다시 저장
  setLocalStorage("searchHistory", history);

  return history;
};

export const deleteSearchHistory = itemToDelete => {
  let history = getLocalStorage("searchHistory", []);

  history = history.filter(item => item.item.name !== itemToDelete.name);

  setLocalStorage("searchHistory", history);
};
