export const SplitRegionName = (value, type) => {
  if (!!value) {
    const regionData = value.replace(/ /g, "").split(",");
    if (type === "country") {
      return regionData[regionData.length - 1] || "";
    } else if (regionData.length > 1 && type === "city") {
      return regionData[regionData.length - 2] || "";
    } else {
      return regionData[0] || "";
    }
  } else {
    return "";
  }
};
