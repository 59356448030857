import { useTranslation } from "next-i18next";
import MakeIcon from "@components/layout/SearchHeader/FilterBoxIcon";
import { Contains } from "@constants/contains";
import { IC_TYPE, TYPE, MAIN_BANNER_KEYWORD_TAB_TYPE, LANDING_TYPE, SEARCH_TYPE_TINVEN } from "@constants/common";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom } from "@store/global";
import React, { useEffect, useState } from "react";
import { IsTripbtozApp, IsTTBBApp } from "@modules/utils/TripbtozApp";
import Image from "next/image";
import { deleteSearchHistory, getSearchHistory, setSearchHistory } from "@modules/utils/searchHistory";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import { removeLocalStorage } from "@modules/utils/storage";
import { setSearchDateCookie, setSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { isMobile } from "react-device-detect";
export default function FilterBoxRecommendV2({
  keywords,
  tab,
  handler,
  searchData
}) {
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  const {
    locale
  } = useRouter();
  const [recommandData, setRecommandData] = useState({}); // SEARCH_A 데이터
  const [recommandLabelData, setRecommandLabelData] = useState({}); // SEARCH_B 데이터
  const [rankData, setRankData] = useState({}); // SEARCH_C 데이터
  const [histroyData, setHistroyData] = useState([]);
  useEffect(() => {
    if (tab === 2 || (isMobile ? router.pathname !== "/search/mobile" : router.pathname !== "/")) {
      setHistroyData(getSearchHistory());
    } else {
      const selectedTabData = keywords?.find(data => data.tab === (tab === 0 ? MAIN_BANNER_KEYWORD_TAB_TYPE.INTERNAL : tab === 1 ? MAIN_BANNER_KEYWORD_TAB_TYPE.EXTERNAL : MAIN_BANNER_KEYWORD_TAB_TYPE.RECENT));
      if (selectedTabData) {
        const searchAData = selectedTabData.items.find(item => item.type === "SEARCH_A");
        const searchBData = selectedTabData.items.find(item => item.type === "SEARCH_B");
        const searchCData = selectedTabData.items.find(item => item.type === "SEARCH_C");
        if (searchAData) {
          setRecommandData(searchAData);
        }
        if (searchBData) {
          setRecommandLabelData(searchBData);
        }
        if (searchCData) {
          setRankData(searchCData);
        }
      }
    }
  }, [keywords, tab]);
  const keywordClick = async item => {
    if (item?.type === TYPE.MD) {
      if (IsTripbtozApp() || IsTTBBApp()) {
        if (item.deep_link || item.web_link) {
          location.href = item.deep_link || item.web_link;
        }
      } else if (item.one_link || item.web_link) {
        if (window) window.open(item.one_link || item.web_link);
      }
    } else {
      await handler(item);
    }
  };
  const calcRoomsInfo = searchData => {
    let totalPeople = 0;
    if (searchData) {
      for (const [key, value] of Object.entries(searchData)) {
        if (key.includes("adults") || key.includes("children")) {
          totalPeople += Number(value);
        }
      }
    }
    return totalPeople;
  };
  return <>
      {tab === 2 || (isMobile ? router.pathname !== "/search/mobile" : router.pathname !== "/") ? <div>
          <div className="flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start">
            <div className="SB_14_100_Medium relative flex
                   flex-shrink-0 flex-grow-0 items-center justify-between gap-3
                   self-stretch px-5 py-2.5 text-Gray-700 tablet:px-4 mobile:pb-[7px] mobile:pt-[21px]">
              <span>{t("main.search.title.recent")}</span>
              {histroyData && histroyData.length > 0 && <span className="C_12_100_Medium cursor-pointer text-Gray-400" onClick={() => {
            removeLocalStorage("searchHistory");
            setHistroyData([]);
          }}>
                  {t("main.search.btn.delete")}
                </span>}
            </div>
            {histroyData && histroyData.length > 0 ? histroyData?.map((data, index) => <div key={index} id={IC_TYPE.RECOMMEND.indexOf(data.item.type) > -1 ? "searching_recommand_keyword" : "searching_recommand_region"} className="relative flex flex-shrink-0 flex-grow-0
                     cursor-pointer items-center justify-start gap-3 self-stretch bg-white
                     px-6 py-2 hover:bg-Bg-OffWhite tablet:h-[54px] tablet:gap-2 tablet:px-4" onClick={() => {
          setSearchHistory(data.item, data.item?.landing_type !== LANDING_TYPE.MD_DETAIL && data.item?.type !== SEARCH_TYPE_TINVEN ? {
            searchData: data.searchData,
            searchQuery: data.searchQuery
          } : null);
          if (data.item?.landing_type === LANDING_TYPE.MD_DETAIL) {
            if (IsTripbtozApp() || IsTTBBApp()) {
              if (data.item.deep_link || data.item.web_link) {
                location.href = data.item.deep_link || data.item.web_link;
              }
            } else if (data.item.one_link || data.item.web_link) {
              if (window) window.open(data.item.one_link || data.item.web_link);
            }
          } else {
            handler(data.item, data.item?.landing_type !== LANDING_TYPE.MD_DETAIL && data.item?.type !== SEARCH_TYPE_TINVEN ? {
              searchData: data.searchData,
              searchQuery: data.searchQuery
            } : null);
          }
        }}>
                  {MakeIcon(Contains.REGION, data.item.icon_type || data.item.searchType)}
                  <div className="flex grow flex-col gap-1 overflow-hidden">
                    <p className="SB_14_100_Medium tablet:P_15_120_Medium line-clamp-1 flex-grow pt-1 text-Gray-900">
                      {data.item.name || data.item.searchText}
                    </p>
                    {data.item?.landing_type !== LANDING_TYPE.MD_DETAIL && data.item?.type !== SEARCH_TYPE_TINVEN && <span className={`C_12_100_Regular flex pb-1 text-Gray-400`}>
                          {data.searchQuery && `${dayjs(data.searchQuery["check-in"]).locale(locale).format(locale === "ko-KR" ? "M. D (ddd)" : "ddd, MMM D")} - ${dayjs(data.searchQuery["check-out"]).locale(locale).format(locale === "ko-KR" ? "M. D (ddd)" : "ddd, MMM D")} · ${t("searchbar.people.label").split(",")[0]} ${data.searchQuery?.rooms},${t("searchbar.people.label").split(",")[1]} ${calcRoomsInfo(data?.searchQuery)}`}
                        </span>}
                  </div>
                  <button className="flex h-8 w-8 items-center justify-center" onClick={event => {
            event.stopPropagation();
            deleteSearchHistory(data.item);
            setHistroyData(getSearchHistory());
          }}>
                    <Image src="/images/sub/ic_cancel_ou_g40.svg" alt="cancelBtn" width={12} height={12} />
                  </button>
                </div>) : <div className="flex h-[360px] w-full items-center justify-center text-Gray-500">
                {t("search.no.newly")}
              </div>}
          </div>
        </div> : <div className="pb-10">
          {/*추천 키워드 영역*/}
          <div className="flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start">
            <div className="SB_14_100_Medium relative flex
                   flex-shrink-0 flex-grow-0 items-center justify-start gap-3
                   self-stretch px-5 py-2.5 text-Gray-700 tablet:px-4 mobile:pb-[7px] mobile:pt-[21px]">
              {recommandData?.title}
            </div>
            {recommandData?.keywords?.map((item, index) => <div key={index} className="relative flex flex-shrink-0 flex-grow-0
                     cursor-pointer items-center justify-start gap-3 self-stretch bg-white
                     px-6 py-2 hover:bg-Bg-OffWhite tablet:h-[54px] tablet:gap-2 tablet:px-4" onClick={() => {
          setSearchDateCookie(item.check_in ? dayjs(item.check_in).toDate() : searchData.startDate, item.check_out ? dayjs(item.check_out).toDate() : searchData.endDate);
          setSearchRoomsCookie(searchData.roomsInfo);
          setSearchHistory(item);
          if (item?.landing_type === LANDING_TYPE.MD_DETAIL) {
            if (IsTripbtozApp() || IsTTBBApp()) {
              if (item.deep_link || item.web_link) {
                location.href = item.deep_link || item.web_link;
              }
            } else if (item.one_link || item.web_link) {
              if (window) window.open(item.one_link || item.web_link);
            }
          } else {
            handler(item);
          }
        }}>
                {MakeIcon(Contains.REGION, item.icon_type)}
                <div className="flex grow flex-col overflow-hidden">
                  <p className="SB_14_100_Medium tablet:P_15_120_Medium line-clamp-1 flex-grow text-Gray-900">
                    {item["name"]}
                  </p>
                </div>
              </div>)}
          </div>

          {/*추천 키워드 레이블 영역*/}
          {recommandLabelData?.keywords?.length > 0 && <div className="flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start">
              <div className="SB_14_100_Medium tablet:SB_14_100_Medium relative flex
                   flex-shrink-0 flex-grow-0 items-center justify-start gap-3
                   self-stretch px-5 pb-3 pt-4 text-Gray-700 tablet:px-4">
                {recommandLabelData?.title}
              </div>
              <div className="flex flex-wrap gap-2 px-5">
                {recommandLabelData?.keywords?.map((item, index) => <div key={index} className="flex h-10 cursor-pointer items-center rounded-[50px] bg-Bg-LightGray px-4 py-[13px]" onClick={() => {
            setSearchDateCookie(item.check_in ? dayjs(item.check_in).toDate() : searchData.startDate, item.check_out ? dayjs(item.check_out).toDate() : searchData.endDate);
            setSearchRoomsCookie(searchData.roomsInfo);
            setSearchHistory(item);
            keywordClick(item);
          }}>
                    <span className="SB_14_100_Medium text-Gray-800">{item.name}</span>
                  </div>)}
              </div>
            </div>}

          {/*인기 검색어 영역*/}
          <div className="hidden mobile:block mobile:pb-5">
            <div className="SB_14_100_Medium px-4 pb-[7px] pt-[21px] text-Gray-800">
              {rankData?.title}
            </div>
            <div className="grid w-full grid-flow-col grid-rows-5 px-4 pt-2 text-Gray-800">
              {rankData?.keywords?.map(keyword => <div key={keyword.id} className="flex cursor-pointer items-center gap-1 px-1 py-2.5" onClick={() => {
            setSearchDateCookie(searchData.startDate, searchData.endDate);
            setSearchRoomsCookie(searchData.roomsInfo);
            setSearchHistory(keyword);
            handler(keyword);
          }}>
                  <span className="SB_14_100_Bold w-5 text-center">{keyword.rank}</span>
                  <span>
                    <Image src={`/images/common/${keyword.state === "up" ? "ic_arrow_up_fi_p50" : keyword.state === "down" ? "ic_arrow_down_fi_bl" : keyword.state === "new" ? "ic_arrow_new_fi_p50" : router.pathname === "/search/mobile" ? "ic_arrow_none_fi_wh_mobile" : "ic_arrow_none_fi_wh"}.png`} alt="rankStateIcon" width={14} height={14} />
                  </span>
                  <span className="SB_14_100_Medium">{keyword.name}</span>
                </div>)}
            </div>
          </div>
        </div>}
    </>;
}