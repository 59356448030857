import { Contains } from "@constants/contains";
import IcHotelFi2 from "@public/images/common/ic_hotel_fi_g70.svg";
import { IC_TYPE } from "@constants/common";
import IcLandmarkFi from "@public/images/common/ic_landmark_fi_g70.svg";
import IcAirportFi from "@public/images/common/ic_airport_fi_g70.svg";
import IcTrainFi from "@public/images/common/ic_train_fi_g70.svg";
import IcSubwayFi from "@public/images/common/ic_subway_fi_g70.svg";
import IcLocateFi2 from "@public/images/common/ic_locate_fi_g70.svg";
import IcRecommend from "@public/images/common/ic_recommend_fi_g90.svg";
export default function MakeIcon(type, ic_type) {
  const selectType = return_type => {
    let colorCode = "",
      icon = null;
    if (type === Contains.HOTEL) {
      colorCode = "#5B94E914";
      icon = <IcHotelFi2 />;
    } else {
      if (IC_TYPE.LANDMARK.indexOf(ic_type) > -1) {
        colorCode = "#F365BB14";
        icon = <IcLandmarkFi />;
      } else if (IC_TYPE.AIRPORT.indexOf(ic_type) > -1) {
        colorCode = "#CD5BE917";
        icon = <IcAirportFi />;
      } else if (IC_TYPE.TRAIN.indexOf(ic_type) > -1) {
        colorCode = "#00A4D114";
        icon = <IcTrainFi />;
      } else if (IC_TYPE.SUBWAY.indexOf(ic_type) > -1) {
        colorCode = "#00B55E12";
        icon = <IcSubwayFi />;
      } else if (IC_TYPE.HOTEL.indexOf(ic_type) > -1) {
        colorCode = "#5B94E914";
        icon = <IcHotelFi2 />;
      } else if (IC_TYPE.RECOMMEND.indexOf(ic_type) > -1) {
        colorCode = "#ff737314";
        icon = <IcRecommend />;
      } else {
        colorCode = "#7373FF14";
        icon = <IcLocateFi2 />;
      }
    }
    return return_type === "color" ? colorCode : icon;
  };
  return <div className={`flex items-center justify-center gap-2.5 rounded-[39px] p-3`} style={{
    backgroundColor: selectType("color")
  }}>
      {selectType("icon")}
    </div>;
}