import { useState, useEffect } from "react";

/**
 * 계속 지속적으로 변하는 값 (value) 을 인자로 전달하고,
 * delay로 전달된 ms 동안 추가 변화가 없을경우 마지막에 변경 된 값을 반환.
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
