import { Contains } from "@constants/contains";
import Highlighter from "react-highlight-words";
import { useTranslation } from "next-i18next";
import { useDomain } from "@modules/hooks/useDomain";
import MakeIcon from "@components/layout/SearchHeader/FilterBoxIcon";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom } from "@store/global";
import { setSearchHistory } from "@modules/utils/searchHistory";
import { setSearchDateCookie, setSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
export default function FilterBoxCard({
  type,
  searchData,
  dataList,
  handler
}) {
  const isTTBB = useDomain();
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기
  const pointColor = isTTBB ? "text-Gray-900" : "text-Primary500";

  // 다국어
  const {
    t
  } = useTranslation("common");
  return <div id="filterBoxCard" className="flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start">
      <div className="SB_14_100_Medium tablet:SB_14_100_Medium relative flex
                   flex-shrink-0 flex-grow-0 items-center justify-start gap-3
                   self-stretch px-5 py-2.5 text-Gray-700 tablet:px-4">
        {mainPageVer2 && type === Contains.RECOMMEND && t("main.search.keyword.recommend")}
        {type === Contains.REGION && t("search.txt.city.search")}
        {type === Contains.HOTEL && t("search.txt.hotel.search")}
      </div>
      {dataList.items.map((info, index) => (type === Contains.REGION && index < 5 || type === Contains.HOTEL && type === Contains.HOTEL && (mainPageVer2 ? index < 15 : index < 10) || mainPageVer2 && type === Contains.RECOMMEND && index < 7) && <div key={index} id={type === Contains.REGION ? "searching_region" : "searching_hotel"} className="relative flex flex-shrink-0 flex-grow-0 cursor-pointer
                     items-center justify-start gap-3 self-stretch bg-white px-6 py-2 hover:bg-Bg-OffWhite
                     tablet:h-[54px] tablet:gap-2 tablet:px-4" onClick={() => {
      if (mainPageVer2 && type === Contains.RECOMMEND) {
        setSearchDateCookie(searchData.startDate, searchData.endDate);
        setSearchRoomsCookie(searchData.roomsInfo);
        // setSearchHistory(info);
        handler(info);
      } else {
        if (mainPageVer2) {
          setSearchDateCookie(searchData.startDate, searchData.endDate);
          setSearchRoomsCookie(searchData.roomsInfo);
          // setSearchHistory({
          //   ...info,
          //   landing_type: type,
          //   icon_type: type,
          // });
        }
        handler(`${info.id}`, info.country ? `${info.name}, ${info.country}` : `${info.name}`, type, info.name);
      }
    }}>
              {MakeIcon(type, type === Contains.RECOMMEND ? info.icon_type : info.type)}
              <div className="flex grow flex-col overflow-hidden">
                <p className="SB_14_100_Medium tablet:B_16_100_Medium overflow-hidden text-ellipsis whitespace-nowrap pb-0.5 text-Gray-900">
                  <Highlighter highlightClassName={`${pointColor} bg-white`} searchWords={searchData.searchText.split("")} autoEscape={true} textToHighlight={info.name || ""} />
                </p>
                <span className="C_12_100_Regular mb-0.5 mt-0.5 text-Gray-500 tablet:text-Gray-600">
                  <Highlighter highlightClassName={`${pointColor} bg-white`} searchWords={searchData.searchText.split("")} autoEscape={true} textToHighlight={info.country || ""} />
                </span>
              </div>
              {type === Contains.REGION && <span className={`SB_14_100_Regular flex w-[116px] justify-end self-start text-Gray-400`}>
                  {t("search.txt.city.cnt.search", {
          n: info.count
        })}
                </span>}
            </div>)}
    </div>;
}