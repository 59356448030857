import dayjs from "dayjs";

export const search = data => {
  return {
    content_ids: data?.searchId || "",
    content_type: data?.searchType || "",
    checkin_date: dayjs(data["check-in"]).format("YYYY.MM.DD"),
    checkout_date: dayjs(data["check-out"]).format("YYYY.MM.DD"),
  };
};
