import { SplitRegionName } from "@modules/utils/splitRegionName";

export const hotelsInfo = data => {
  const [id, locale, region_name, star_rating, checkin_date, checkout_date] = data;
  return {
    content_ids: id, // 호텔ID
    content_type: "hotel",
    currency: locale === "ko-KR" ? "KRW" : "USD",
    country: SplitRegionName(region_name, "country"),
    city: SplitRegionName(region_name, "city"),
    region: SplitRegionName(region_name, "region"),
    content_category: star_rating,
    checkin_date,
    checkout_date,
  };
};
