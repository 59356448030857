export const signup = data => {
  const {
    email,
    birthday,
    terms_accepted,
    location_policy,
    ad_policy_accepted,
    auth_id,
    provider,
  } = data;
  return {
    em: email,
    db: birthday,
    status: terms_accepted,
    content_type: provider || "email",
  };
};
