export const checkoutInfo = data => {
  const [checkOutData, locale] = data;
  return {
    content_ids: checkOutData?.checkouts?.hotel.id,
    content_type: "hotel",
    value: checkOutData?.checkouts?.checkout.price.selling_price,
    currency: locale === "ko-KR" ? "KRW" : "USD",
    checkin_date: checkOutData?.checkouts?.checkout.check_in,
    checkout_date: checkOutData?.checkouts?.checkout.check_out,
    country: checkOutData?.checkouts?.hotel.country_code,
  };
};
